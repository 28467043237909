import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./Questions.css";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";

function Questions() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return { valid: false, message: t("validation.emailRequired") };
    } else if (!emailRegex.test(email)) {
      return { valid: false, message: t("validation.invalidEmail") };
    }
    return { valid: true, message: "" };
  };

  const showTemporaryNotification = (message) => {
    setNotificationMessage(message);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 2000);
  };

  const debouncedSendMessage = useCallback(
    debounce(() => {
      setIsSubmitting(false);
    }, 5000),
    []
  );

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (isSubmitting) return;
    const emailValidation = validateEmail(email);
    if (!emailValidation.valid) {
      showTemporaryNotification(emailValidation.message);
      return;
    }

    if (!message.trim()) {
      showTemporaryNotification(t("validation.messageRequired"));
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios
        .post(
          "https://arastagh-am-api-production.up.railway.app/send-message",
          {
            message: `Email or Phone: ${email}\nMessage: ${message}`,
          }
        )
        .catch((error) => {
          console.error("Error:", error);
          throw error;
        })
        .then((response) => {
          setEmail("");
          setMessage("");
          showTemporaryNotification(t("notifications.message"));
          debouncedSendMessage();
        });
    } catch (error) {
      showTemporaryNotification("Failed to send message.");
      setIsSubmitting(false);
    }
  };

  return (
    <div className="Questions">
      {showNotification && (
        <div className="notification">{notificationMessage}</div>
      )}
      <h1>{t("questions.title")}</h1>

      <div className="input-group">
        <input
          className="email-container"
          name="Email Address"
          placeholder={t("questions.emailphoneinput")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="message">
        <input
          className="message-container"
          name="Message"
          placeholder={t("questions.messageinput")}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSubmit();
          }}
        />
        <SendIcon
          className={`send-icon ${isSubmitting ? "disabled" : ""}`}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

export default Questions;
