import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Helpers/ScrollToTop";
import "./App.css";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Catalog from "./Pages/Catalog";
import Contact from "./Pages/Contact";
import Ceiling from "./Pages/Ceiling";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ceilings/:ceilingName" element={<Ceiling />} />
        </Routes>
        <Footer />
      </Router>
    </I18nextProvider>
  );
}

export default App;
