import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./Assets/data/eng/eng.json";
import arm from "./Assets/data/arm/arm.json";

const savedLanguage = localStorage.getItem("selectedLanguage") || "en";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    arm: {
      translation: arm,
    },
  },
  lng: savedLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
