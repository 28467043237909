import React from "react";
import "./Catalog.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cubota from "../Assets/images/cubota.jpg";
import grilliato from "../Assets/images/grilliato.jpg";
import armstrong from "../Assets/images/armstrong.jpg";
import stretch from "../Assets/images/stretch.jpg";
import aluminium from "../Assets/images/aluminium.jpg";
import pvc from "../Assets/images/pvc.jpg";
import thermowall from "../Assets/images/thermowall.jpg";

function Catalog() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const catalogItems = [
    { name: t("catalog.options.0"), name_en: "grilliato", image: grilliato },
    { name: t("catalog.options.1"), name_en: "stretch", image: stretch },
    { name: t("catalog.options.2"), name_en: "cubota", image: cubota },
    { name: t("catalog.options.3"), name_en: "armstrong", image: armstrong },
    { name: t("catalog.options.4"), name_en: "aluminium", image: aluminium },
    { name: t("catalog.options.5"), name_en: "pvc", image: pvc },
    { name: t("catalog.options.6"), name_en: "thermowall", image: thermowall },
  ];

  return (
    <div className="Catalog">
      <div className="catalog-items">
        {catalogItems.map((item, index) => (
          <div
            key={index}
            onClick={() => navigate(`/ceilings/${item.name_en.toLowerCase()}`)}
            className="catalog-item"
          >
            <img src={item.image} alt={item.name} />
            <h2 className="title">{item.name}</h2>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Catalog;
