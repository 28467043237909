import React, { useEffect, useRef, useState } from "react";
import "./HomeCeilings.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import grilliato from "../Assets/images/grilliato.jpg";
import stretch from "../Assets/images/stretch.jpg";
import cubota from "../Assets/images/cubota.jpg";
import armstrong from "../Assets/images/armstrong.jpg";
import aluminium from "../Assets/images/aluminium.jpg";
import pvc from "../Assets/images/pvc.jpg";
import thermowall from "../Assets/images/thermowall.jpg";

const imageMap = {
  Grilliato: grilliato,
  Stretch: stretch,
  Cubota: cubota,
  Armstrong: armstrong,
  Aluminium: aluminium,
  PVC: pvc,
  Thermowall: thermowall,
};

function HomeCeilings({ ceiling }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const ceilingRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (ceilingRef.current) {
      observer.observe(ceilingRef.current);
    }

    return () => {
      if (ceilingRef.current) {
        observer.unobserve(ceilingRef.current);
      }
    };
  }, []);

  return (
    <div
      className={`HomeCeilings ${isVisible ? "visible" : ""}`}
      ref={ceilingRef}
    >
      <div className="ceiling-info">
        <h1>{ceiling.name}</h1>
        <p>{ceiling.description}</p>
        <button
          onClick={() => navigate(`/ceilings/${ceiling.name_en.toLowerCase()}`)}
        >
          {t("carousel.button")}
        </button>{" "}
      </div>
      <div className="ceiling-image">
        <img src={imageMap[ceiling.name_en]} alt={ceiling.name_en} />
      </div>
    </div>
  );
}

export default HomeCeilings;
