import React, { useState } from "react";
import "./Contact.css";
import "../Helpers/CopyToClipboard.css";
import { useTranslation } from "react-i18next";
import Questions from "../Components/Questions";
import Map from "../Components/Map";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

function Contact() {
  const { t } = useTranslation();
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  let copytoClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setShowCopyNotification(true);
    setTimeout(() => {
      setShowCopyNotification(false);
    }, 2000);
  };
  return (
    <div className="Contact">
      <div className="map-container">
        <div className="map-info">
          <div className="address">
            <h1>{t("contact.names.0")}</h1>
            <p
              onClick={() => copytoClipboard("Vardananc 69/5 Yerevan, Armenia")}
            >
              <FontAwesomeIcon icon={faLocationDot} className="contact-icon" />
              {t("contact.address")}
            </p>
          </div>
          <div className="phone">
            <h1>{t("contact.names.1")}</h1>
            <p onClick={() => copytoClipboard("+37410123456")}>
              <FontAwesomeIcon icon={faPhone} className="contact-icon" />
              +374 10 123456
            </p>
          </div>
          <div className="email">
            <h1>{t("contact.names.2")}</h1>
            <p onClick={() => copytoClipboard("info@arastax.am")}>
              <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
              info@arastax.am
            </p>
            {showCopyNotification && (
              <div className="notification">{t("notifications.copy")}</div>
            )}
          </div>
          <div className="social media">
            <h1>{t("contact.names.3")}</h1>
            <div className="media-icons">
              <FacebookIcon
                className="media-icon"
                onClick={() =>
                  window.open("https://www.facebook.com/arastagham/", "_blank")
                }
              />
              <InstagramIcon
                className="media-icon"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/arastagh.am/",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="map">
          <Map />
        </div>
      </div>
      <Questions />
    </div>
  );
}

export default Contact;
