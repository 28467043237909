import React from "react";
import "./About.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import aboutus from "../Assets/images/aboutus.png";
import collage from "../Assets/images/collage.jpg";
import ameriabank from "../Assets/logos/ameriabank.png";
import dalma from "../Assets/logos/dalma.jpg";
import dilitown from "../Assets/logos/dilitown.jpg";
import erebunimall from "../Assets/logos/erebunimall.jpg";
import kulthome from "../Assets/logos/kulthome.jpg";
import onex from "../Assets/logos/onex.jpg";
import parmasupermarket from "../Assets/logos/parmasupermarket.jpg";
import sasfoodcourt from "../Assets/logos/sasfoodcourt.png";
import wasabi from "../Assets/logos/wasabi.png";
import yerevanpark from "../Assets/logos/yerevanpark.jpg";
import hardrockcafe from "../Assets/logos/hardrockcafe.jpg";
import davysgym from "../Assets/logos/davisgym.jpg";
import bestwestern from "../Assets/logos/bestwestern.png";
import dijitayn from "../Assets/logos/dijitayn.jpg";

function About() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const logos = [
    ameriabank,
    dalma,
    dilitown,
    erebunimall,
    kulthome,
    onex,
    parmasupermarket,
    sasfoodcourt,
    wasabi,
    yerevanpark,
    hardrockcafe,
    davysgym,
    bestwestern,
    dijitayn,
  ];

  const ceilings = t("ceilings", { returnObjects: true });

  return (
    <div className="About">
      <div className="aboutus">
        <img src={aboutus} alt="About Us" />
        <p className="info">{t("aboutus.description")}</p>
      </div>

      <div className="partners">
        <h1>{t("aboutus.title")}</h1>
        <div className="partners_logos">
          <div className="inner">
            {[...logos, ...logos].map((logo, index) => (
              <img key={index} src={logo} alt={`Partner ${index}`} />
            ))}
          </div>
        </div>
      </div>

      <div className="collage">
        <div className="collage-text">
          <p>{t("aboutus.info.title")}</p>
          <ul>
            {ceilings.map((ceiling, index) => (
              <li
                key={index}
                onClick={() =>
                  navigate(`/ceilings/${ceiling.name_en.toLowerCase()}`)
                }
              >
                <ArrowRightAltIcon />
                {ceiling.name}
              </li>
            ))}
          </ul>
        </div>
        <img src={collage} alt="Collage" />
      </div>
    </div>
  );
}

export default About;
