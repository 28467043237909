import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "@fortawesome/fontawesome-free/css/all.min.css";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: null,
  iconUrl: null,
  shadowUrl: null,
});

const Map = () => {
  const position = [40.171434, 44.52144];

  const customIcon = L.divIcon({
    className: "custom-div-icon",
    html: '<div style="background-color: transparent; border: none;"><i class="fas fa-map-marker-alt" style="font-size: 30px; color: red;"></i></div>',
    iconSize: [30, 42],
    iconAnchor: [15, 42],
    popupAnchor: [0, -42],
  });

  return (
    <MapContainer
      center={position}
      zoom={16}
      style={{ height: "400px", width: "100%", borderRadius: "10px" }}
    >
      <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />

      <Marker position={position} icon={customIcon}>
        <Popup>Arastagh AM</Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
