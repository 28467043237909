import React, { useState } from "react";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import "../Helpers/CopyToClipboard.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const ceilings = t("ceilings", { returnObjects: true });

  const handleNavigate = (ceiling) => {
    navigate(`/ceilings/${ceiling.name_en.toLowerCase()}`);
  };

  const copytoClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setShowCopyNotification(true);
    setTimeout(() => setShowCopyNotification(false), 2000);
  };

  return (
    <div className="Footer">
      <div className="footer">
        <div id="company">
          <h1>{t("footer.components.0.name")}</h1>
          <p>{t("footer.components.0.text")}</p>
        </div>
        <div id="catalog">
          <h1>{t("footer.components.1.name")}</h1>
          {ceilings.map((ceiling, index) => (
            <p
              key={index}
              className="option"
              onClick={() => handleNavigate(ceiling)}
            >
              {ceiling.name}
            </p>
          ))}
        </div>
        <div id="contact">
          <h1>{t("footer.components.2.name")}</h1>
          <p className="option" onClick={() => copytoClipboard("+37441106767")}>
            +374 41 10 67 67
          </p>
          <p
            className="option"
            onClick={() => copytoClipboard("info@arastax.am")}
          >
            info@arastax.am
          </p>
          {showCopyNotification && (
            <div className="notification">{t("notifications.copy")}</div>
          )}
        </div>
        <div id="social-media">
          <h1>{t("footer.components.3.name")}</h1>
          <div className="social-media-icons">
            <FacebookIcon
              onClick={() =>
                window.open("https://www.facebook.com/arastagh.am/", "_blank")
              }
              className="icon"
              sx={{ fontSize: 30 }}
            />
            <InstagramIcon
              onClick={() =>
                window.open("https://www.instagram.com/arastagh.am/", "_blank")
              }
              className="icon"
              sx={{ fontSize: 30 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
