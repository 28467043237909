import React from "react";
import Carousel from "../Components/Carousel";
import HomeCeilings from "../Components/HomeCeilings";
import Questions from "../Components/Questions";
import { useTranslation } from "react-i18next";
import "./Home.css";

function Home() {
  const { t } = useTranslation();

  return (
    <div className="Home">
      <Carousel />
      <div className="home-ceilings">
        {t("ceilings", { returnObjects: true }).map((ceiling, index) => (
          <HomeCeilings key={index} ceiling={ceiling} />
        ))}
      </div>
      <Questions />
    </div>
  );
}

export default Home;
